import { climateSensorReportTypeOptions } from "../actions/vehicles"
import { SUBTREES_FETCHED } from "../types/customer"

export const getOverweightLogsId = (boxId, start, end) => {
  return (boxId ?? 'all') + '_' + Math.floor(start) + '_' + Math.floor(end)
}

export const userTerms = (state = null, action) => {
  switch (action.type) {
    case 'FETCHED_USER_TERMS':
      return action.data
    default:
      return state
  }
}

export const acceptedUserTerms = (state = null, action) => {
  switch (action.type) {
    case 'FETCHED_ACCEPTED_USER_TERMS':
      return action.data
    case 'FAILED_FETCHING_ACCEPTED_USER_TERMS':
      return {...state}
    default:
      return state
  }
}

export const saving = (state = { 
  ajaxError: false, 
  updateMaintenancePeriodComment: {}, 
  updateMaintenancePeriodDocument: {} ,
  removeMaintenancePeriodDocument: {}
}, action) => {
    switch (action.type) {
      case 'ADD_ASSET_DOCUMENT':
        return {
          ...state,
          addAssetDocument: 'saving'
        }
      case 'ADDING_DOCUMENT_TO_ASSET_FAILED':
        return {
          ...state,
          addAssetDocument: 'failed'
        }
      case 'DOCUMENT_ADDED_TO_ASSET':
        return {
          ...state,
          addAssetDocument: null
        }
      case 'UPDATE_MAINTENANCE_PERIOD_COMMENT':
        const withNewId = {...state.updateMaintenancePeriodComment}
        withNewId[action.periodId] = 'saving'
        return {
          ...state,
          updateMaintenancePeriodComment: withNewId
        }
      case 'UPDATING_MAINTENANCE_PERIOD_COMMENT_FAILED':
        const withNewFailure = {...state.updateMaintenancePeriodComment}
        withNewFailure[action.periodId] = 'failed'
        return {
          ...state,
          updateMaintenancePeriodComment: withNewFailure
        }
      case 'UPDATING_MAINTENANCE_PERIOD_COMMENT_SUCCEEDED':
        const withoutId = {...state.updateMaintenancePeriodComment}
        withoutId[action.periodId] = null
        return {
          ...state,
          updateMaintenancePeriodComment: withoutId
        }
      case 'UPDATE_DOCUMENT_OF_PERIOD':
        const withNewDocumentId = {...state.updateMaintenancePeriodDocument}
        withNewDocumentId[action.periodId] = 'saving'
        return {
          ...state,
          updateMaintenancePeriodDocument: withNewDocumentId
        }
      case 'UPDATING_DOCUMENT_OF_PERIOD_FAILED':
        const withNewDocumentFailure = {...state.updateMaintenancePeriodDocument}
        withNewDocumentFailure[action.periodId] = 'failed'
        return {
          ...state,
          updateMaintenancePeriodDocument: withNewDocumentFailure
        }
      case 'UPDATING_DOCUMENT_OF_PERIOD_SUCCEEDED':
        const withoutDocumentId = {...state.updateMaintenancePeriodDocument}
        withoutDocumentId[action.periodId] = null
        return {
          ...state,
          updateMaintenancePeriodDocument: withoutDocumentId
        }
      case 'REMOVE_DOCUMENT_FROM_PERIOD':
        const withNewDocId = {...state.updateMaintenancePeriodDocument}
        withNewDocId[action.periodId] = 'saving'
        return {
          ...state,
          removeMaintenancePeriodDocument: withNewDocId
        }
      case 'REMOVING_DOCUMENT_FROM_PERIOD_FAILED':
        const withDocFailure = {...state.updateMaintenancePeriodDocument}
        withDocFailure[action.periodId] = 'failed'
        return {
          ...state,
          removeMaintenancePeriodDocument: withDocFailure
        }
      case 'REMOVING_DOCUMENT_FROM_PERIOD_SUCCEEDED':
        const withoutDocId = {...state.updateMaintenancePeriodDocument}
        withoutDocId[action.periodId] = null
        return {
          ...state,
          removeMaintenancePeriodDocument: withoutDocId
        }
      case 'REMOVE_ASSET_DOCUMENT':
        const withDocumentId = {...state.removeAssetDocument}
        withDocumentId[action.documentId] = 'saving'
        return {
          ...state,
          removeAssetDocument: withDocumentId
        }
      case 'REMOVING_ASSET_DOCUMENT_FAILED':
        const withRemoveFailure = {...state.removeAssetDocument}
        withRemoveFailure[action.documentId] = 'failed'
        return {
          ...state,
          removeAssetDocument: withRemoveFailure
        }
      case 'REMOVING_ASSET_DOCUMENT_SUCCEEDED':
        const withoutNewDocId = {...state.removeAssetDocument}
        withoutNewDocId[action.documentId] = null
        return {
          ...state,
          removeAssetDocument: withoutNewDocId
        }
      case 'UPLOAD_DOCUMENT':
        return{
          ...state,
          uploadDocument: 'uploading',
        }
      case 'UPLOAD_IMAGE':
        return{
          ...state,
          uploadImage: 'uploading',
        }
      case 'UPLOADING_IMAGE_SUCCESSFULLY':
        return{
          ...state,
          uploadImage: 'uploaded',
        }
      case 'UPLOADING_IMAGE_FAILED':
        return{
          ...state,
          uploadImage: 'failed',
        }
      case 'CLEAR_UPLOADED_IMAGES':
        return {
          ...state,
          uploadImage: null
        }
      case 'MARK_MAINTENANCE_PERIOD_AS_DONE':
        const addPeriodToMarkDone = {...(state.markMaintenanceTaskAsDone ?? {})}
        addPeriodToMarkDone[action.periodId] = 'saving'

        return {
          ...state,
          markMaintenanceTaskAsDone: addPeriodToMarkDone
        }
      case 'MAINTENANCE_PERIOD_DONE':
        const withoutPeriod = {...(state.markMaintenanceTaskAsDone ?? {})}
        delete withoutPeriod[action.periodId]
        return {
          ...state,
          markMaintenanceTaskAsDone: withoutPeriod
        }
      case 'MAINTENANCE_PERIOD_DONE_FAILED':
        const addFailedPeriodToMarkDone = {...(state.markMaintenanceTaskAsDone ?? {})}
        addFailedPeriodToMarkDone[action.periodId] = 'failed'
        return {
          ...state,
          markMaintenanceTaskAsDone: addFailedPeriodToMarkDone
        }
      case 'UPLOADING_DOCUMENT_SUCCESSFUL':
        return{
          ...state,
          uploadDocument: 'uploaded',
        }
      case 'UPLOADING_DOCUMENT_FAILED':
        return{
          ...state,
          uploadDocument: 'failed',
        }
      case 'CLEAR_UPLOADED_DOCUMENTS':
        return {
          ...state,
          uploadDocument: null,
        }
      case "CHANGE_VEHICLE_NAME":
        return {
          ...state,
          changeVehicleName: "saving",
        };
      case "VEHICLE_NAME_UPDATED":
        return {
          ...state,
          changeVehicleName: null,
        };
      case "VEHICLE_NAME_UPDATE_FAILED":
        return {
          ...state,
          changeVehicleName: "failed",
        };

      case "UPDATE_MILEAGE":
        return {
          ...state,
          changeVehicleMileage: "saving",
        };
      case "MILEAGE_UPDATED":
        return {
          ...state,
          changeVehicleMileage: null,
        };
      case "MILEAGE_UPDATE_FAILED":
        return {
          ...state,
          changeVehicleMileage: "failed",
        };
      case "ADD_USER":
        return {
          ...state,
          addUser: "saving",
        };
      case "USER_ALREADY_EXISTS":
        return {
          ...state,
          addUser: "conflict",
        };
      case "INCORRECT_EMAIL":
        return {
          ...state,
          addUser: "incorrect_email",
        };
      case "USER_ADDED":
        return {
          ...state,
          addUser: null,
        };
      case "ADDING_USER_FAILED":
        return {
          ...state,
          addUser: "failed",
        };
      case "AJAX_ERROR":
        return {
          ...state,
          ajaxError: true,
        };
      case "DISMISS_AJAX_ERROR":
        return {
          ...state,
          ajaxError: false,
        };
      case "SET_LICENSE_NUMBER":
        return {
          ...state,
          setLicenseNumber: "saving",
        };
      case "LICENSE_NUMBER_UPDATED":
        return {
          ...state,
          setLicenseNumber: null,
        };
      case "LICENSE_NUMBER_UPDATE_FAILED":
        return {
          ...state,
          setLicenseNumber: "failed",
        };
      case "UPDATE_DRIVING_LOG_NOTE":
        const newList = (state.drivingLogNote ?? [])
        newList.push({id: action.logId, saving: action.note})
        return {
          ...state,
          drivingLogNote: newList
        }
      case "DRIVING_LOG_NOTE_UPDATED":
        const toUpdate = { ...state };
        return {
          ...state,
          drivingLogNote: toUpdate.drivingLogNote?.filter(d => d.id !== action.logId)
        }
      case "DRIVING_LOG_NOTE_UPDATE_FAILED": {
        const toUpdate2 = { ...state };
        return {
          ...state,
          drivingLogNote: toUpdate2.drivingLogNote?.filter(d => d.id !== action.logId)
        }
      }
      case "UPDATE_ASSET":
      case "UPDATE_ASSET_AND_MAINTENANCE_PLAN": {
        let withNewAsset = {
          ...(state.updateAsset ?? {})
        }
        withNewAsset[action.assetId] = "saving"

        return {
          ...state,
          updateAsset: withNewAsset
        }
      }
      case "UPDATING_ASSET_SUCCEEDED": {
        let withoutAssetToUpdate = (state.updateAsset ?? {})
        delete withoutAssetToUpdate[action.assetId]

        return {
          ...state,
          updateAsset: withoutAssetToUpdate
        }
      }
      case "UPDATING_ASSET_FAILED": {
        let withFailedAssetToUpdate = {
          ...(state.updateAsset ?? {})
        }
        withFailedAssetToUpdate[action.assetId] = "failed"

        return {
          ...state,
          updateAsset: withFailedAssetToUpdate
        }
      }
      case "CREATE_NEW_HOMEBASE": {
        return {
          ...state,
          newHomebase: "saving"
        }
      }
      case "CREATE_NEW_HOMEBASE_SUCCEEDED": {
        return {
          ...state,
          newHomebase: null
        }
      }
      case "CREATE_NEW_HOMEBASE_FAILED": {
        return {
          ...state,
          newHomebase: "failed"
        }
      }
      case "UPDATE_MAINTENANCE_PLAN_AND_TASK":
      case "UPDATE_ASSET_AND_MAINTENANCE_PLAN": {
        let withNewPlanAndTaskToUpdate = {
          ...(state.updateMaintenancePlan ?? {})
        }
        withNewPlanAndTaskToUpdate[action.maintenancePlanId] = "saving"

        return {
          ...state,
          updateMaintenancePlan: withNewPlanAndTaskToUpdate
        }
      }
      case "UPDATING_MAINTENANCE_PLAN_AND_TASK_SUCCEEDED": {
        let withoutPlanAndTaskToUpdate = (state.updateMaintenancePlan ?? {})
        delete withoutPlanAndTaskToUpdate[action.maintenancePlanId]
        
        return {
          ...state,
          updateMaintenancePlan: withoutPlanAndTaskToUpdate
        }
      }
      case "UPDATING_MAINTENANCE_PLAN_AND_TASK_FAILED": {
        let withFailedPlanAndTaskToUpdate = {
          ...(state.updateMaintenancePlan ?? {})
        }
        withFailedPlanAndTaskToUpdate[action.maintenancePlanId] = "failed"
        return {
          ...state,
          updateMaintenancePlan: withFailedPlanAndTaskToUpdate,
        }
      }
      case "REMOVE_ASSET_AND_MAINTENANCE_PLAN": {
        let withPlanToRemove = {
          ...(state.removeAssetAndMaintenancePlan ?? {})
        }
        withPlanToRemove[action.maintenancePlanId] = "saving"

        return {
          ...state,
          removeAssetAndMaintenancePlan: withPlanToRemove,
        }
      }
      case "ASSET_AND_MAINTENANCE_PLAN_REMOVED": {
        let withoutPlanAndTaskToRemove = (state.removeAssetAndMaintenancePlan ?? {})
        delete withoutPlanAndTaskToRemove[action.maintenancePlanId]

        return {
          ...state,
          removeAssetAndMaintenancePlan: withoutPlanAndTaskToRemove
        }
      }
      case "REMOVING_ASSET_AND_MAINTENANCE_PLAN_FAILED": {
        let withPlanThatFailedToRemove = {
          ...(state.removeAssetAndMaintenancePlan ?? {})
        }
        withPlanThatFailedToRemove[action.maintenancePlanId] = "failed"
        return {
          ...state,
          removeAssetAndMaintenancePlan: withPlanThatFailedToRemove
        }
      }
      default:
        return state;
    }
  }

  export const loading = (
    state = { 
      ajaxError: false, 
      counter_progress: 0,
      counter_fetchLastKnownPositions: 0,
      counter_fetchingTrackersAddresses: 0,

      fetchDrivingLogs: {},
      fetchDrivingLogDetails: {},
      fetchWeightReport: {},
      fetchAssetDocuments: {},

      fetchSubtrees: 'loading'
    },
    action) => {
    let updatedState

    switch (action.type) {
      case SUBTREES_FETCHED: 
        return {
          ...state,
          fetchSubtrees: null
        }
      case 'FAILED_FETCHING_ACCEPTED_USER_TERMS':
        return {
          ...state,
          fetchSubtrees: null
        }
      case 'APP_STARTED': 
        return {
          ...state,
          fetchSubtrees: 'loading'
        } 
      case 'FETCH_BEACON_SNAPSHOTS':
        return {
          ...state, 
          fetchBeaconSnapshots: 'loading',
        }
      case 'BEACON_SNAPSHOTS_FETCHED':
        return {
          ...state, 
          fetchBeaconSnapshots: null,
        }
      case 'FETCHING_BEACON_SNAPSHOTS_FAILED':
        return {
          ...state, 
          fetchBeaconSnapshots: 'failed',
        }
      case 'FETCH_CURRENT_DRIVERS':
        return {
          ...state, 
          fetchCurrentDrivers: 'loading'
        }
      case 'FETCHING_CURRENT_DRIVERS_FAILED':
        return {
          ...state, 
          fetchCurrentDrivers: 'failed'
        }
      case 'CURRENT_DRIVERS_FETCHED':
        return {
          ...state, 
          fetchCurrentDrivers: null
        }
      case 'FETCH_DRIVING_LOGS':
        updatedState = state.fetchDrivingLogs
        updatedState = {
          ...updatedState,
          [action.driverId ?? action.boxId]: 'loading'
        }
        return {
          ...state, 
          fetchDrivingLogs: updatedState,
        }
      case 'DRIVING_LOGS_FETCHED':
        updatedState = state.fetchDrivingLogs
        updatedState = {
          ...updatedState,
          [action.driverId ?? action.boxId]: null
        }
        return {
          ...state, 
          fetchDrivingLogs: updatedState,
        }
      case 'FETCHING_DRIVING_LOGS_FAILED':
        updatedState = state.fetchDrivingLogs
        updatedState = {
          ...updatedState,
          [action.driverId ?? action.boxId]: 'failed'
        }
        return {
          ...state, 
          fetchDrivingLogs: updatedState,
        }
      case 'FETCH_BATTERY_REPORT':
        return {
          ...state, 
          fetchBatteryReport: 'loading',
        }
      case 'FETCH_TEMPERATURE_REPORT':
      return {
        ...state, 
        fetchTemperatureReport: 'loading',
      }
      case 'FETCH_HUMIDITY_REPORT':
      return {
        ...state, 
        fetchHumidityReport: 'loading',
      }
      case 'BATTERY_REPORT_FETCHED': 
        return {
          ...state,
          fetchBatteryReport: null,
        }
      case 'TEMPERATURE_REPORT_FETCHED': 
        return {
          ...state,
          fetchTemperatureReport: null,
        }
      case 'HUMIDITY_REPORT_FETCHED': 
        return {
          ...state,
          fetchHumidityReport: null,
        }
      case 'FETCHING_BATTERY_REPORT_FAILED':
        return {
          ...state,
          fetchBatteryReport: 'failed',
        }
      case 'FETCHING_TEMPERATURE_REPORT_FAILED':
        return {
          ...state,
          fetchTemperatureReport: 'failed',
        }
      case 'FETCHING_HUMIDITY_REPORT_FAILED':
        return {
          ...state,
          fetchHumidityReport: 'failed',
        }
      case 'FETCH_OVERWEIGHT_REPORT':
        updatedState = state.fetchWeightReport
        updatedState = {
          ...updatedState,
          [getOverweightLogsId(action.boxId, action.start, action.end)]: 'loading'
        }

        return {
          ...state,
          fetchWeightReport: 'loading',

          fetchWeightReport_v2: updatedState // TODO: get rid of the other one (non-v2) once we update the weighing report to the new one
        }
      case 'OVERWEIGHT_REPORT_FETCHED': 
        updatedState = state.fetchWeightReport
        updatedState = {
          ...updatedState,
          [getOverweightLogsId(action.boxId, action.start, action.end)]: null
        }

        return {
          ...state,
          fetchWeightReport: null,

          fetchWeightReport_v2: updatedState // TODO: get rid of the other one (non-v2) once we update the weighing report to the new one
        };
      case 'FETCHING_OVERWEIGHT_REPORT_FAILED':
        updatedState = state.fetchWeightReport
        updatedState = {
          ...updatedState,
          [getOverweightLogsId(action.boxId, action.start, action.end)]: 'failed'
        }

        return {
          ...state,
          fetchWeightReport: 'failed',
        
          fetchWeightReport_v2: updatedState
        };
      case 'FETCH_TYRE_PRESSURE_REPORT':
        return {
          ...state, 
          progress: 'loading',
          counter_progress: state.counter_progress + 1
        }
      case 'TYRE_PRESSURE_REPORT_FETCHED':
        return {
          ...state,
          progress: state.counter_progress > 1 ? state.progress : null,
          counter_progress: state.counter_progress - 1
        }
      case 'FETCHING_TYRE_PRESSURE_REPORT_FAILED':
        return {
          ...state,
          progress: state.counter_progress > 1 ? state.progress : 'failed',
          counter_progress: state.counter_progress - 1
        }
      case 'FETCH_VEHICLES':
        return {
          ...state, 
          fetchVehicles: 'loading',
        }
      case 'VEHICLES_FETCHED':
        if (!action.data?.length) return {
          ...state,
          fetchVehicles: null,
        }
        return {
          ...state,
          fetchVehicles: null
        }
      case 'FETCHING_VEHICLES_FAILED':
        return {
          ...state,
          fetchVehicles: 'failed'
        }
      case 'FETCH_VEHICLES_SORTED':
        return {
          ...state, 
          fetchVehiclesSorted: 'loading'
        }
      case 'VEHICLES_FETCHED_SORTED': 
        return {
          ...state,
          fetchVehiclesSorted: null
        }
      case 'FETCHING_VEHICLES_SORTED_FAILED': 
        return {
          ...state,
          fetchVehiclesSorted: 'failed'
        }
      case 'FETCH_VEHICLES_DATA':
        return {
          ...state, 
          fetchVehiclesData: 'loading'
        }
      case 'VEHICLES_DATA_FETCHED': 
        return {
          ...state,
          fetchVehiclesData: null          
        }
      case 'FETCHING_VEHICLES_DATA_FAILED':
        return {
          ...state,
          fetchVehiclesData: 'failed'
        }
      case 'FETCH_ASSET_TRACKER_REPORT':
        return {
          ...state,
          fetchAssetTrackerReport: 'loading'
        }
      case 'FETCHING_ASSET_TRACKER_REPORT_FAILED':
        return {
          ...state,
          fetchAssetTrackerReport: 'failed'
        }
      case 'ASSET_TRACKER_REPORT_FETCHED': 
        return {
          ...state,
          fetchAssetTrackerReport: null
        }
      case 'FETCH_ASSET_TRACKERS':
        return {
          ...state,
          fetchAssetTrackers: 'loading'
        }
      case 'FETCHING_ASSET_TRACKERS_FAILED':
        return {
          ...state,
          fetchAssetTrackers: 'failed'
        }
      case 'ASSET_TRACKERS_FETCHED': 
        return {
          ...state,
          fetchAssetTrackers: null
        }
      case 'FETCH_ASSET_TRACKER_ADDRESSES':
        return {
          ...state,
          fetchAssetTrackersAddresses: 'loading'
        }
      case 'FETCHING_ASSET_TRACKER_ADDRESSES_FAILED':
        return {
          ...state,
          fetchAssetTrackersAddresses: 'failed'
        }
      case 'ASSET_TRACKER_ADDRESSES_FETCHED': 
        return {
          ...state,
          fetchAssetTrackersAddresses: null
        }
      case 'FETCH_DRIVING_LOG_SUMMARIES':
        return {
          ...state, 
          fetchDrivingLogSummaries: 'loading',
        }
      case 'DRIVING_LOG_SUMMARIES_FETCHED': 
        return {
          ...state,
          fetchDrivingLogSummaries: null,
        }
      case 'FETCHING_DRIVING_LOG_SUMMARIES_FAILED':
        return {
          ...state,
          fetchDrivingLogSummaries: 'failed',
        }
      case 'FETCH_DRIVING_LOG_DETAILS':
        updatedState = state.fetchDrivingLogDetails
        updatedState = {
          ...updatedState,
          [(action.driverId ?? action.boxId) + '_' + action.start]: 'loading'
        }
        return {
          ...state, 
          fetchDrivingLogDetails: updatedState,
        }
      case 'DRIVING_LOG_DETAILS_FETCHED': 
        updatedState = state.fetchDrivingLogDetails
        updatedState = {
          ...updatedState,
          [(action.driverId ?? action.boxId) + '_' + action.start]: null
        }
        return {
          ...state, 
          fetchDrivingLogDetails: updatedState,
        }
      case 'FETCHING_DRIVING_LOG_DETAILS_FAILED':
        updatedState = state.fetchDrivingLogDetails
        updatedState = {
          ...updatedState,
          [(action.driverId ?? action.boxId) + '_' + action.start]: 'failed'
        }
        return {
          ...state, 
          fetchDrivingLogDetails: updatedState,
        }
      case 'FETCH_WARNING_LOGS':
        return {
          ...state, 
          fetchWarningLogs: 'loading',
        }
      case 'WARNING_LOGS_FETCHED': 
        return {
          ...state,
          fetchWarningLogs: null,
          settingMapDate: false
        }
      case 'FETCHING_WARNING_LOGS_FAILED':
        return {
          ...state,
          fetchWarningLogs: 'failed',
          settingMapDate: false
        }   
      case 'SET_MAP_DATE':
        return{
          ...state,
          settingMapDate: true
        }
      case 'FETCH_LAST_KNOWN_POSITIONS':
        return {
          ...state, 
          fetchLastKnownPositions: 'loading',
        }
      case 'HAS_ALL_MAP_DAY_DATA':
        return {
          ...state,
          settingMapDate: false
        }
      case 'FETCHED_LAST_KNOWN_POSITIONS': 
        return {
          ...state,
          fetchLastKnownPositions: null,
          settingMapDate: false
        }
      case 'FETCHING_LAST_KNOWN_POSITIONS_FAILED':
        return {
          ...state,
          fetchLastKnownPositions: 'failed',
          settingMapDate: false
        }
      case 'FETCH_TOLL_REPORT':
        return {
          ...state,
          fetchTollReport: 'loading'
        }
      case 'TOLL_REPORTS_FETCHED':
        return {
          ...state,
          fetchTollReport: null
        }
      case 'FETCHING_TOLL_REPORTS_FAILED':
        return {
          ...state,
          fetchTollReport: 'failed'
        }
      case 'FETCH_TOLL_REPORT_SUMMARIES':
        return {
          ...state,
          fetchTollReportSummaries: 'loading'
        }
      case 'TOLL_REPORT_SUMMARIES_FETCHED':
        return {
          ...state,
          fetchTollReportSummaries: null
        }
      case 'FETCHING_TOLL_REPORT_SUMMARIES_FAILED':
        return {
          ...state,
          fetchTollReportSummaries: 'failed'
        }
      case 'FETCH_VEHICLE_ROUTES':
        return {
          ...state,
          fetchVehicleRoutes: 'loading',
        }
      case 'FETCH_VEHICLE_ROUTES_DETAILED':
        updatedState = state.fetchVehicleRoutesDetailed ?? {}
        const key = action.startEpoch + '_' + action.endEpoch 
        updatedState[key] = 'loading'
        return {
          ...state,
          fetchVehicleRoutesDetailed: updatedState
        }

      case 'FETCHED_VEHICLE_ROUTES_DETAILED':
        const updatedState2 = state.fetchVehicleRoutesDetailed ?? {}
        const key2 = action.startEpoch + '_' + action.endEpoch 
        if(!updatedState2 || !updatedState2[key2]) return state
        updatedState2[key2] = 'fetched'
        return {
          ...state,
          fetchVehicleRoutesDetailed: updatedState2
        }
      case 'FETCHING_VEHICLE_ROUTES_DETAILED_FAILED':
        const updatedState3 = state.fetchVehicleRoutesDetailed ?? {}
        const key3 = action.startEpoch + '_' + action.endEpoch 
        if(!updatedState3 || !updatedState3[key3]) return state
        updatedState3[key3] = 'failed'
        return {
          ...state,
          fetchVehicleRoutesDetailed: updatedState3
        }
      case 'FETCHED_VEHICLE_ROUTES':
        return {
          ...state,
          fetchVehicleRoutes: null,
          settingMapDate: false
        }
      case 'FETCHING_VEHICLE_ROUTES_FAILED':
        return {
          ...state,
          fetchVehicleRoutes: 'failed',
        }
      case 'FETCH_VEHICLE_POSITIONS': {
        return {
          ...state,
          fetchVehiclePositions: 'loading',
        }
      }
      case 'FETCHED_VEHICLE_POSITIONS':
        return {
          ...state,
          fetchVehiclePositions: null,
        }
      case 'FETCHING_VEHICLE_POSITIONS_FAILED': {
        return {
          ...state,
          fetchVehiclePositions: 'failed',
        }
      }
      case 'FETCH_USERS':
        return {
          ...state,
          fetchUsers: 'loading'
        }
      case 'USERS_FETCHED':
        return {
          ...state,
          fetchUsers: null
        }
      case 'FETCHING_USERS_FAILED':
        return {
          ...state,
          fetchUsers: 'failed'
        }
      case 'AJAX_ERROR':
      return {
        ...state,
        ajaxError: true
      }
      case 'DISMISS_AJAX_ERROR':
      return {
        ...state,
        ajaxError: false
      }
      case 'FETCH_MILEAGE':
        return {
          ...state, 
          fetchMileage: 'loading'
        }
      case 'FETCHING_MILEAGE_FAILED':
        return {
          ...state, 
          fetchMileage: 'failed'
        }
      case 'MILEAGE_FETCHED':
      case 'NO_MILEAGE':
        return {
          ...state, 
          fetchMileage: null
        }
      case 'FETCH_TRACKER_ADDRESS':
        return{
          ...state,
          counter_fetchingTrackersAddresses: state.counter_fetchingTrackersAddresses + 1
        }
      case 'TRACKER_ADDRESS_FETCHED':
        return {
          ...state,
          counter_fetchingTrackersAddresses: state.counter_fetchingTrackersAddresses - 1
        }
      case 'CLEAR_ASSET_TRACKERS': 
      return{
        ...state,
        counter_fetchingTrackersAddresses: 0
      }
      case 'FETCH_LPS_CHARGING_DATA':
        return{
          ...state,
          fetchLpsChargingData: 'loading'
        }
      case 'FETCHING_LPS_CHARGING_DATA_FAILED':
        return{
          ...state,
          fetchLpsChargingData: 'failed'
        }
      case 'LPS_CHARGING_DATA_FETCHED':
        return{
          ...state,
          fetchLpsChargingData: null
        }
      case 'FETCH_LPS_FOOTPRINT':
        return{
          ...state,
          fetchLpsFootprint: 'loading'
        }
      case 'FETCHING_LPS_FOOTPRINT_FAILED':
        return{
          ...state,
          fetchLpsFootprint: 'failed'
        }
      case 'LPS_FOOTPRINT_FETCHED':
        return{
          ...state,
          fetchLpsFootprint: null
        }
      case 'FETCH_MAINTENANCE_TASKS': 
        return{
          ...state,
          fetchMaintenanceTasks: 'loading'
        }
      case 'FETCHING_MAINTENANCE_TASKS_FAILED': 
        return{
          ...state,
          fetchMaintenanceTasks: 'failed'
        }
      case 'FETCHING_MAINTENANCE_TASKS_SUCCESSFUL': 
        return{
          ...state,
          fetchMaintenanceTasks: null
        }
      case 'FETCH_MAINTENANCE_TASKS_SUMMARY': 
        return{
          ...state,
          fetchMaintenanceSummary: 'loading'
        }
      case 'FETCHING_MAINTENANCE_TASKS_SUMMARY_FAILED': 
        return{
          ...state,
          fetchMaintenanceSummary: 'failed'
        }
      case 'FETCHING_MAINTENANCE_TASKS_SUMMARY_SUCCESSFUL': 
        return{
          ...state,
          fetchMaintenanceSummary: null
        }
      case 'FETCH_MAINTENANCE_CATEGORIES':
      case 'FETCH_ASSET_CATEGORIES':
        return {
          ...state,
          fetchMaintenanceCategories: 'loading'
        }
      case 'FETCHING_MAINTENANCE_CATEGORIES_FAILED':
      case 'FETCHING_ASSET_CATEGORIES_FAILED':
        return {
          ...state,
          fetchMaintenanceCategories: 'failed'
        }
      case 'FETCHING_MAINTENANCE_CATEGORIES_SUCCESSFUL':
      case 'FETCHING_ASSET_CATEGORIES_SUCCESSFUL':
        return {
          ...state,
          fetchMaintenanceCategories: null
        }
      case 'FETCH_MAINTENANCE_HOMEBASES':
        return {
          ...state,
          fetchMaintenanceHomebases: 'loading'
        }
      case 'FETCHING_MAINTENANCE_HOMEBASES_FAILED':
        return {
          ...state,
          fetchMaintenanceHomebases: 'failed'
        }
      case 'FETCHING_MAINTENANCE_HOMEBASES_SUCCESSFUL':
        return {
          ...state,
          fetchMaintenanceHomebases: null
        }
      case 'FETCH_MAINTENANCE_PLANS':
        return {
          ...state,
          fetchMaintenancePlan: 'loading'
        }
      case 'FETCHING_MAINTENANCE_PLANS_FAILED':
        return {
          ...state,
          fetchMaintenancePlan: 'failed'
        }
      case 'FETCHING_MAINTENANCE_PLANS_SUCCESSFUL':
        return {
          ...state,
          fetchMaintenancePlan: null
        }
      case 'FETCH_PERIODS_WITH_DOCUMENTS':
        return{
          ...state,
          fetchPeriodsWithDocs: 'loading'
        }
      case 'FETCHING_PERIODS_WITH_DOCUMENTS_FAILED':
        return{
          ...state,
          fetchPeriodsWithDocs: 'failed'
        }
      case 'FETCHING_PERIODS_WITH_DOCUMENTS_SUCCESSFUL':
        return{
          ...state,
          fetchPeriodsWithDocs: null
        }
      case 'FETCH_ASSET_DOCUMENTS': {
        const withAddedId = {...state.fetchAssetDocuments}
        withAddedId[action.assetId] = 'loading'
        return {
          ...state,
          fetchAssetDocuments: withAddedId
        }
      }
      case 'FETCHING_ASSET_DOCUMENTS_FAILED': {
        const withFailedId = {...state.fetchAssetDocuments}
        withFailedId[action.assetId] = 'failed'
        return {
          ...state,
          fetchAssetDocuments: withFailedId
        }
      }
      case 'ASSET_DOCUMENTS_FETCHED': {
        const withoutId = {...state.fetchAssetDocuments}
        delete withoutId[action.assetId]
        return {
          ...state,
          fetchAssetDocuments: withoutId
        }
      }
      case 'FETCH_SECURITY_REPORTS_SUMMARY':
        return{
          ...state,
          fetchSecuritySummary: 'loading'
        }
      case 'FETCHING_SECURITY_REPORTS_SUMMARY_FAILED':
        return{
          ...state,
          fetchSecuritySummary: 'failed'
        }
      case 'SECURITY_REPORTS_SUMMARY_FETCHED':
        return{
          ...state,
          fetchSecuritySummary: null
        }
      case 'FETCH_VEHICLES_SECURITY_REPORTS':
      updatedState = state.fetchVehiclesSecurityReports
      updatedState = {
        ...updatedState,
        [action.vin]: 'loading'
      }
      return {
        ...state, 
        fetchVehiclesSecurityReports: updatedState,
      }
      case 'VEHICLES_SECURITY_REPORTS_FETCHED':
        updatedState = state.fetchVehiclesSecurityReports
        updatedState = {
          ...updatedState,
          [action.vin]: null
        }
        return {
          ...state, 
          fetchVehiclesSecurityReports: updatedState,
        }
      case 'FETCHING_VEHICLES_SECURITY_REPORTS_FAILED':
        updatedState = state.fetchVehiclesSecurityReports
        updatedState = {
          ...updatedState,
          [action.vin]: 'failed'
        }
        return {
          ...state, 
          fetchVehiclesSecurityReports: updatedState,
        }
      case 'NOTIFICATIONS_FETCHED':
        return{
          ...state,
          fetchNotifications: null
        }
      case 'FETCH_NOTIFICATIONS':
        return{
          ...state,
          fetchNotifications: 'loading'
        }
      case 'FETCHING_NOTIFICATIONS_FAILED':
        return{
          ...state,
          fetchNotifications: 'failed'
        }
      case 'FETCH_LOCK_REPORTS': 
        return{
          ...state,
          fetchLockReports: 'loading'
        }
      case 'FETCHING_LOCK_REPORTS_FAILED': 
        return{
          ...state,
          fetchLockReports: 'failed'
        }
      case 'FETCHING_LOCK_REPORTS_SUCCEEDED': 
        return{
          ...state,
          fetchLockReports: null
        }
      case 'FETCH_LOCK_SUMMARY': 
        return{
          ...state,
          fetchLockSummary: 'loading'
        }
      case 'FETCHING_LOCK_SUMMARY_FAILED': 
        return{
          ...state,
          fetchLockSummary: 'failed'
        }
      case 'FETCHING_LOCK_SUMMARY_SUCCEEDED': 
        return{
          ...state,
          fetchLockSummary: null
        }
      default:
        return state;
    }
}

export const log_loading = (state = {
  counter_fetchBatteryLogs: 0,
  counter_fetchLpsLogs: 0,
  counter_fetchVehicleTemperatureLogs: 0,
  counter_fetchVehicleHumidityLogs: 0,
  counter_fetchWeightLogs: 0,
  counter_fetchLPSReportSummaries: 0,
  counter_fetchOverweightReportSummaries: 0
}, action) => {
  switch (action.type) {
    case 'FETCH_VEHICLE_BATTERY_LOGS':
      return {
        ...state, 
        fetchBatteryLogs: 'loading',
        fetchBatteryLogs_id: action.id,
        counter_fetchBatteryLogs: state.counter_fetchBatteryLogs + 1
      }
    case 'VEHICLE_BATTERY_LOGS_FETCHED': 
      return {
        ...state,
        fetchBatteryLogs: state.counter_fetchBatteryLogs > 1 ? state.fetchBatteryLogs : null,
        fetchBatteryLogs_id: state.counter_fetchBatteryLogs > 1 ? state.fetchBatteryLogs_id : null,
        counter_fetchBatteryLogs: state.counter_fetchBatteryLogs - 1
      }
    case 'FETCHING_VEHICLE_BATTERY_LOGS_FAILED':
      return {
        ...state,
        fetchBatteryLogs: state.counter_fetchBatteryLogs > 1 ? state.fetchBatteryLogs : 'failed',
        fetchBatteryLogs_id: state.counter_fetchBatteryLogs > 1 ? state.fetchBatteryLogs_id : null,
        counter_fetchBatteryLogs: state.counter_fetchBatteryLogs - 1
      }
      case 'FETCH_LPS_BATTERY_LOGS':
      return {
        ...state, 
        fetchLpsLogs: 'loading',
        fetchLpsLogs_id: action.id,
        counter_fetchLpsLogs: state.counter_fetchLpsLogs + 1
      }
    case 'LPS_BATTERY_LOGS_FETCHED': 
      return {
        ...state,
        fetchLpsLogs: state.counter_fetchLpsLogs > 1 ? state.fetchLpsLogs : null,
        fetchLpsLogs_id: state.counter_fetchLpsLogs > 1 ? state.fetchLpsLogs_id : null,
        counter_fetchLpsLogs: state.counter_fetchLpsLogs - 1
      }
    case 'FETCHING_LPS_BATTERY_LOGS_FAILED':
      return {
        ...state,
        fetchLpsLogs: state.counter_fetchLpsLogs > 1 ? state.fetchLpsLogs : 'failed',
        fetchLpsLogs_id: state.counter_fetchLpsLogs > 1 ? state.fetchLpsLogs_id : null,
        counter_fetchLpsLogs: state.counter_fetchLpsLogs - 1
      }
      case 'FETCH_VEHICLE_TEMPERATURE_LOGS':
        return {
          ...state, 
          fetchVehicleTemperatureLogs: 'loading',
          fetchVehicleTemperatureLogs_id: action.id,
          counter_fetchVehicleTemperatureLogs: state.counter_fetchVehicleTemperatureLogs + 1
        }
      case 'VEHICLE_TEMPERATURE_LOGS_FETCHED':
        return {
          ...state,
          fetchVehicleTemperatureLogs: state.counter_fetchVehicleTemperatureLogs > 1 ? state.fetchVehicleTemperatureLogs : null,
          fetchVehicleTemperatureLogs_id: state.counter_fetchVehicleTemperatureLogs > 1 ? state.fetchVehicleTemperatureLogs_id : null,
          counter_fetchVehicleTemperatureLogs: state.counter_fetchVehicleTemperatureLogs - 1
        }
      case 'FETCHING_VEHICLE_TEMPERATURE_LOGS_FAILED':
        return {
          ...state,
          fetchVehicleTemperatureLogs: state.counter_fetchVehicleTemperatureLogs > 1 ? state.fetchVehicleTemperatureLogs : 'failed',
          fetchVehicleTemperatureLogs_id: state.counter_fetchVehicleTemperatureLogs > 1 ? state.fetchVehicleTemperatureLogs_id : null,
          counter_fetchVehicleTemperatureLogs: state.counter_fetchVehicleTemperatureLogs - 1
        }
        case 'FETCH_VEHICLE_HUMIDITY_LOGS':
        return {
          ...state, 
          fetchVehicleHumidityLogs: 'loading',
          fetchVehicleHumidityLogs_id: action.id,
          counter_fetchVehicleHumidityLogs: state.counter_fetchVehicleHumidityLogs + 1
        }
      case 'VEHICLE_HUMIDITY_LOGS_FETCHED':
        return {
          ...state,
          fetchVehicleHumidityLogs: state.counter_fetchVehicleHumidityLogs > 1 ? state.fetchVehicleHumidityLogs : null,
          fetchVehicleHumidityLogs_id: state.counter_fetchVehicleHumidityLogs > 1 ? state.fetchVehicleHumidityLogs_id : null,
          fetchVehicleHumidityLogs: state.counter_fetchVehicleHumidityLogs - 1
        }
      case 'FETCHING_VEHICLE_HUMIDITY_LOGS_FAILED':
        return {
          ...state,
          fetchVehicleHumidityLogs: state.counter_fetchVehicleHumidityLogs > 1 ? state.fetchVehicleHumidityLogs : 'failed',
          fetchVehicleHumidityLogs_id: state.counter_fetchVehicleHumidityLogs > 1 ? state.fetchVehicleHumidityLogs_id : null,
          counter_fetchVehicleHumidityLogs: state.counter_fetchVehicleHumidityLogs - 1
        }
    case 'FETCH_VEHICLE_WEIGHTS':
      return {
        ...state, 
        fetchWeightLogs: 'loading',
        fetchWeightLogs_id: action.id,
        counter_fetchWeightLogs: state.counter_fetchWeightLogs + 1
      }
    case 'VEHICLE_WEIGHTS_FETCHED': 
      return {
        ...state,
        fetchWeightLogs: state.counter_fetchWeightLogs > 1 ? state.fetchWeightLogs : null,
        fetchWeightLogs_id: state.counter_fetchWeightLogs > 1 ? state.fetchWeightLogs_id : null,
        counter_fetchWeightLogs: state.counter_fetchWeightLogs - 1
      }
    case 'FETCHING_VEHICLE_WEIGHTS_FAILED':
      return {
        ...state,
        fetchWeightLogs: state.counter_fetchWeightLogs > 1 ? state.fetchWeightLogs : 'failed',
        fetchWeightLogs_id: state.counter_fetchWeightLogs > 1 ? state.fetchWeightLogs_id : null,
        counter_fetchWeightLogs: state.counter_fetchWeightLogs - 1
      } 
    case 'FETCH_LPS_REPORT_SUMMARIES':
      return {
        ...state, 
        fetchLPSReportSummaries: 'loading',
      }
    case 'LPS_REPORT_SUMMARIES_FETCHED':
      return {
        ...state,
        fetchLPSReportSummaries: null,
      }
    case 'FETCHING_LPS_REPORT_SUMMARIES_FAILED':
      return {
        ...state,
        fetchLPSReportSummaries: 'failed',
      }
    case "FETCH_OVERWEIGHT_REPORT_SUMMARIES":
      return {
        ...state,
        fetchOverweightReportSummaries: "loading",
      };
    case "OVERWEIGHT_REPORT_SUMMARIES_FETCHED":
      return {
        ...state,
        fetchOverweightReportSummaries: null,
      };
    case "FETCHING_OVERWEIGHT_REPORT_SUMMARIES_FAILED":
      return {
        ...state,
        fetchOverweightReportSummaries: "failed",
      };
    default:
      return state;
  }
}

export const vehicle_loading = (state = {
  fetchVehicles: null,
  fetchVehicle: null, counter_fetchVehicle: 0
}, action) => {
  switch (action.type) {
    case 'FETCH_VEHICLE':
      return {
        ...state, 
        fetchVehicle: 'loading',
        counter_fetchVehicle: state.counter_fetchVehicle + 1
      }
    case 'VEHICLE_FETCHED': 
      return {
        ...state,
        fetchVehicle: state.counter_fetchVehicle > 1 ? state.fetchVehicle : action?.status === 'forbidden' ? action?.status : null ,
        counter_fetchVehicle: state.counter_fetchVehicle - 1
      }
    case 'FETCHING_VEHICLE_FAILED':
      return {
        ...state,
        fetchVehicle: state.counter_fetchVehicle > 1 ? state.fetchVehicle : 'failed',
        counter_fetchVehicle: state.counter_fetchVehicle - 1
      } 
    case 'FETCH_VEHICLES':
      return {
        ...state, 
        fetchVehicles: 'loading',
      }
    case 'VEHICLES_FETCHED': 
      return {
        ...state,
        fetchVehicles: null,
      }
    case 'FETCHING_VEHICLES_FAILED':
      return {
        ...state,
        fetchVehicles: 'failed',
      }
    default:
      return state;
  }
}

export const downloading = (state = {
  toll_report: null,
  driving_logs: null,
  lps_log_summaries: null,
}, action) => {
  let boxIds, driverIds
  switch (action.type) {
    case 'TOLL_REPORTS_FETCHED':
    case 'FETCH_TOLL_REPORT_SUMMARIES':
    case 'FETCH_TOLL_REPORT':
      return {
        ...state,
        toll_report: null
      }
    case 'DOWNLOAD_TOLL_REPORT':
      return {
        ...state,
        toll_report: {
          status: 'working', boxId: action.boxId, driverId: action.driverId
        }
      }
    case 'TOLL_REPORTS_DOWNLOADED':
      return {
        ...state,
        toll_report: {
          status: 'done', boxId: action.boxId, driverId: action.driverId
        }
      }
    case 'DOWNLOADING_TOLL_REPORTS_FAILED':
      return {
        ...state,
        toll_report: {
          status: 'failed', boxId: action.boxId, driverId: action.driverId
        }
      }
    case 'DRIVING_LOGS_FETCHED':
      return {
        ...state,
        driving_logs: null
      }
    case 'DOWNLOAD_DRIVING_LOGS':
      boxIds = state.driving_logs?.boxIds ?? []
      if (action.boxId) (boxIds ?? []).push(action.boxId)

      driverIds = state.driving_logs?.driverIds ??[]
      if (action.driverId) (driverIds).push(action.driverId)

      return {
        ...state,
        driving_logs: {
          status: 'working', boxIds: boxIds, driverIds: driverIds
        }
      }
    case 'DRIVING_LOGS_DOWNLOADED':
      boxIds = state.driving_logs?.boxIds
      if (action.boxId) {
        boxIds = boxIds?.filter(boxId => boxId !== action.boxId)
      }

      driverIds = state.driving_logs?.driverIds
      if (action.driverId) {
        driverIds = driverIds?.filter(driverId => driverId !== action.driverId)
      }

      return {
        ...state,
        driving_logs: {
          status: 'done', boxIds: boxIds, driverIds: driverIds
        }
      }
    case 'DOWNLOADING_DRIVING_LOGS_FAILED':
      boxIds = state.driving_logs?.boxIds
      if (action.boxId) {
        boxIds = boxIds?.filter(boxId => boxId !== action.boxId)
      }

      driverIds = state.driving_logs?.driverIds
      if (action.driverId) {
        driverIds = driverIds?.filter(driverId => driverId !== action.driverId)
      }

      return {
        ...state,
        driving_logs: {
          status: 'failed', boxIds: boxIds, driverIds: action.driverIds
        }
      }
      case 'DOWNLOAD_CLIMATE_LOGS':
      return {
        ...state,
        climate_logs: {
          temperature: action.typeOfReport === climateSensorReportTypeOptions.temperature ? {
            status: 'working', boxId: action.vehicle?.boxId
          } : state.climate_logs?.temperature,
          humidity: action.typeOfReport === climateSensorReportTypeOptions.humidity ? {
            status: 'working', boxId: action.vehicle?.boxId
          } : state.climate_logs?.humidity,
        }
      }
    case 'CLIMATE_LOGS_DOWNLOADED':
      return {
        ...state,
        climate_logs: {
          temperature: action.typeOfReport === climateSensorReportTypeOptions.temperature ? {
            status: 'done', boxId: action.vehicle?.boxId
          } : state.climate_logs?.temperature,
          humidity: action.typeOfReport === climateSensorReportTypeOptions.humidity ? {
            status: 'done', boxId: action.vehicle?.boxId
          } : state.climate_logs?.humidity,
        }
      }
    case 'DOWNLOADING_CLIMATE_LOGS_FAILED':
      return {
        ...state,
        climate_logs: {
          temperature: action.typeOfReport === climateSensorReportTypeOptions.temperature ? {
            status: 'failed', boxId: action.vehicle?.boxId
          } : state.climate_logs?.temperature,
          humidity: action.typeOfReport === climateSensorReportTypeOptions.humidity ? {
            status: 'failed', boxId: action.vehicle?.boxId
          } : state.climate_logs?.humidity,
        }
      }
    default:
      return state
  }
}

export const showWarningBar = (state = [], action) => {
  switch(action.type){
    case 'SHOW_WARNING_NOTIFICATION':
      return{
        showBar: true,
        message: action.message,
        alertType: action.alertType
      }
    case 'HIDE_WARNING_NOTIFICATION':
      return {
        showBar: false,
        message: null
      }
    default:
      return state
  }
}

export const showSuccessBar = (state = [], action) => {
  switch(action.type){
    case 'SHOW_SUCCESS_NOTIFICATION':
      return {
        showBar: true,
        message: action.message
      }
    case 'HIDE_SUCCESS_NOTIFICATION':
      return {
        showBar: false,
        message: null
      }
    default: return state
  }
}

export const uploaded_file = (state = null, action) => {
  switch (action.type) {
    case 'UPLOADING_DOCUMENT_SUCCESSFUL':
      return action.data
    case 'CLEAR_UPLOADED_FILES': // TODO: call this when closing modals!
      return null
    case 'DOCUMENT_REMOVED':
    case 'CLEAR_UPLOADED_DOCUMENTS':
    case 'UPLOADING_DOCUMENT_FAILED':
      return null // if we ever have more than one file at the same time, need something more advanced than this
    default: return state
  }
}
