export const dismissAjaxError = () => (
  {
    type: 'DISMISS_AJAX_ERROR'
  }
)

export const fetchUserTerms = accessToken => (
  {
    type: 'FETCH_USER_TERMS',
    accessToken
  }
)

export const fetchAcceptedUserTerms = accessToken => (
  {
    type: 'FETCH_ACCEPTED_USER_TERMS',
    accessToken
  }
)

export const acceptUserTerms = accessToken => (
  {
    type: 'ACCEPT_USER_TERMS',
    accessToken
  }
)

export const hideFailBar = () =>(
  {
    type: 'HIDE_WARNING_NOTIFICATION'
  }
)

export const hideSuccessBar = () => (
  {
    type: 'HIDE_SUCCESS_NOTIFICATION'
  }
)

export const hasAllMapDayData = () => ({
  type: 'HAS_ALL_MAP_DAY_DATA'
})

export const toggleSound = (isSound) => ({
  type: "TOGGLE_SOUND",
  isSound: isSound
})